@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
:root {
  color-scheme: dark;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: gold;
}

.input-container {
  width: 100%;
  padding: 0 20px;
  display: flex;
}



#type{
  color:white !important;font-size:21px;

}